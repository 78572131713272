import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p className="font-face-lobster">w</p>
        <p className="text">wardrobe.</p>
        <p className="text">find us on the app store.</p>
      </header>
    </div>
  );
}

export default App;
